.projects {
    overflow: hidden;

    &__hero {
        position: relative;

        &__back-button {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $white;
            font-size: 20px;
            padding-left: 50px;
            text-decoration: none;
            z-index: 2;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                height: 1px;
                background: $white;
            }
        }
    }

    &__objetivos {
        &__container {

            &--top {
                padding: 50px 0;
    
                @include mq(md) {
                    padding: 100px 0 50px 0;
                }
            }

            &--bottom {
                padding: 0 0 50px 0;
    
                @include mq(md) {
                    padding: 0 0 100px 0;
                }
            }
        }
    }

    &--meridional {
        &__hero {
            height: 100vh;
            background: $green;
            position: relative;

            &__image {
                position: absolute;
                bottom: 70px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 1;
                
                @include mq(md) {
                    width: 75%;
                    bottom: 0;
                }

                @include customMq(1500px) {
                    width: 65%;
                }

                img {
                    height: 100px;
                    width: auto;
                    
                    @include mq(md) {
                        width: 100%;
                        height: auto;
                    }
                }
            }

            &__scroll {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 60px;
                background: none;
                border: none;
                text-decoration: none;
                z-index: 3;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 30px;
                    width: 1px;
                    background: $white;
                }
            }

            &__scroll-text {
                margin: 0;
                color: $white;
            }

            &__logo {
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }

                img {
                    margin-left: auto;
                    display: block;
                    width: 150px;
                }
            }

            &__text {
                margin: 0;
                font-weight: 700;
                color: $white;
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }
            }

            &__container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;

                @include mq(md) {
                    height: 40vh;
                }
            }

            &__container-title {
                color: $white;
                font-weight: 700;
                font-size: 80px;
                margin: 0;
                line-height: .9;

                @include mq(md) {
                    font-size: 130px;
                }

                &:nth-child(1) {
                    margin-top: 50px;

                    @include mq(md) {
                        padding-right: 4vw;
                        z-index: 0;
                        margin-top: 0;
                    }
                }

                &:nth-child(2) {
                    @include mq(md) {
                        padding-right: 6vw;
                        z-index: 2;
                    }
                }
            }

            &__container-text {
                color: $white;
                font-weight: 300;
                font-size: 22px;
                margin: 0;
                margin-bottom: 10px;
            }
        }

        &__campana {
            position: relative;

            &__container { 
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                
                @include mq(md) {
                    display: flex;
                    top: 0;
                    bottom: auto;
                }
            }

            &__col {
                height: 0;
                width: 100%;
                padding-bottom: 100%;
                overflow: hidden;
                position: relative;
                
                @include mq(md) {
                    width: 50%;
                    padding-bottom: 50%;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 100%;
                    height: auto;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                padding-top: 10vw;
                padding-bottom: 110vw;

                @include mq(md) {
                    height: 50vw;
                    padding-bottom: 0;
                    padding-top: 0;
                    width: 80%;
                }
            }

            &__title {
                font-size: 30px;
                font-weight: 900;
                margin: 0;
                margin-bottom: 40px;

                @include mq(md) {
                    font-size: 50px;
                }
            }

            &__text {
                margin: 0;
                font-size: 16px;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }

        &__objetivos {
            background: $lightBlack;
            padding: 50px 0 0 0;
            
            @include mq(md) {
                padding: 0;
            }

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
            }

            &__text {
                color: $white;
                width: 80%;
                margin: 0;
            }

            &__item {
                color: $white;
            }
        }

        &__videos {
            overflow: hidden;

            .slick-slider {
                margin-bottom: -4px !important;
                margin-top: -4px;
            }

            .slick-prev,
            .slick-next {
                display: block !important;
            }

            .slick-dots {
                display: none !important;
            }

            &__slide {
                height: 0;
                padding-bottom: 56.5%;
                width: 100%;
                overflow: hidden;
                position: relative;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }

        &__mosaic {
            padding: 50px 0 0 0;
            margin-bottom: -20px;
            
            @include mq(md) {
                margin-bottom: -50px;
                padding: 100px 0 0 0;
            }

            &__title {
                font-size: 40px;
                font-weight: 900;
                margin: 0;
                margin-bottom: 40px;

                @include mq(md) {
                    margin-bottom: 0;
                }
            }

            &__image {
                width: 100%;
                position: relative;

                img {
                    margin-left: auto;
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__facts {
            background: $lightBlack;
            padding: 50px 0;
            z-index: 2;
            position: relative;

            @include mq(md) {
                padding: 100px 0;
            }

            &__wrapper {
                @include mq(md) {
                    width: 55%;
                }
            }

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
                font-size: 40px;
            }

            &__text {
                color: $white;
                margin: 0;
            }

            &__group {
                border-top: 1px solid $white;
                margin-top: 40px;

                &--margin {
                    @include mq(md) {
                        margin-top: 0;
                    }
                }

            }

            &__percentage {
                color: $white;
                font-size: 80px;
                font-weight: 900;
                display: block;
                margin-top: 10px;
                
                @include mq(md) {
                    margin-bottom: 40px;
                    font-size: 100px;
                    margin-top: 0;
                }

            }

            &__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }

            &__container-wrapper {
                margin-top: 20px;
            }

            &__container-title {
                color: $white;
                font-size: 24px;
                margin: 0;
                margin-bottom: 10px;
            }

            &__image {
                width: 100%;
                margin-top: 20px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &__container-percentage {
                color: $white;
                display: block;
                font-weight: 900;
                font-size: 30px;
                margin-top: 20px;

                @include mq(md) {
                    margin-top: 0;
                }
            }
        }
    }

    &--kabala {
        &__hero {
            height: 100vh;
            background: $green;
            position: relative;
            background: url('/images/projects/kabala/hero.jpg') no-repeat center center / cover;

            &__scroll {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 60px;
                background: none;
                border: none;
                text-decoration: none;
                z-index: 3;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 30px;
                    width: 1px;
                    background: $white;
                }
            }

            &__scroll-text {
                margin: 0;
                color: $white;
            }

            &__logo {
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }

                img {
                    margin-left: auto;
                    display: block;
                    width: 100px;
                }
            }

            &__text {
                margin: 0;
                font-weight: 700;
                color: $white;
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }

                span {
                    display: block;
                    font-weight: 300;
                }
            }

            &__container {
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                
                @include mq(md) {
                    padding-top: 40vh;
                }
            }

            &__container-title {
                color: $white;
                font-weight: 700;
                font-size: 80px;
                margin: 0;
                line-height: .9;

                @include mq(md) {
                    font-size: 130px;
                }

                &:nth-child(1) {
                    margin-top: 50px;

                    @include mq(md) {
                        padding-right: 4vw;
                        z-index: 0;
                        margin-top: 0;
                    }
                }

                &:nth-child(2) {
                    @include mq(md) {
                        padding-right: 6vw;
                        z-index: 2;
                    }
                }
            }

            &__container-text {
                color: $white;
                font-weight: 300;
                font-size: 22px;
                margin: 0;
                margin-bottom: 10px;
            }
        }

        &__objetivos {
            background: $lightBlack;
            padding: 50px 0 0 0;
            
            @include mq(md) {
                padding: 0;
            }

            &__container {
                padding: 0 0 50px 0;

                @include mq(md) {
                    padding: 100px 0;
                }
            }

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
            }

            &__text {
                color: $white;
                width: 80%;
                margin: 0;
            }

            &__item {
                color: $white;
            }
        }

        &__videos {
            overflow: hidden;

            .slick-slider {
                margin-bottom: -4px !important;
                margin-top: -4px;
            }

            .slick-prev,
            .slick-next {
                display: block !important;
            }

            .slick-dots {
                display: none !important;
            }

            &__slide {
                height: 0;
                padding-bottom: 56.5%;
                width: 100%;
                overflow: hidden;
                position: relative;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }

        &__facts {
            background: $salmon;
            padding: 50px 0;
            z-index: 2;
            position: relative;

            @include mq(md) {
                padding: 100px 0;
            }

            &__wrapper {
                @include mq(md) {
                    width: 55%;
                }
            }

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
                font-size: 40px;
            }

            &__text {
                color: $white;
                margin: 0;
                font-size: 14px;

                @include mq(md) {
                    font-size: 16px;
                }


                // br {
                //     display: none;

                // }
            }

            &__group {
                border-top: 1px solid $white;
                margin-top: 40px;

                &--margin {
                    @include mq(md) {
                        margin-top: 0;
                    }
                }

            }

            &__percentage {
                color: $white;
                font-size: 70px;
                font-weight: 900;
                display: block;
                margin-top: 10px;
                
                @include mq(md) {
                    margin-bottom: 40px;
                    font-size: 100px;
                    margin-top: 0;
                }

            }

            &__container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }

            &__container-wrapper {
                margin-top: 20px;
            }

            &__container-title {
                color: $white;
                font-size: 24px;
                margin: 0;
                margin-bottom: 10px;
            }

            &__image {
                width: 100%;
                margin-top: 20px;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &__container-percentage {
                color: $white;
                display: block;
                font-weight: 900;
                font-size: 30px;
                margin-top: 20px;

                @include mq(md) {
                    margin-top: 0;
                }
            }
        }
    }

    &--alfaparf {
        &__hero {
            height: 100vh;
            background: $pink;
            position: relative;

            &__image {
                z-index: 1;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;

                @include mq(md) {
                    width: 50%;
                }

                img {
                    position: absolute;
                    width: 50%;
                    bottom: -10%;
                    right: -10%;

                    @include mq(md) {
                        right: 0;
                        bottom: -20%;
                        width: auto;
                        height: 100%;
                        margin-left: auto;
                        display: block;
                    }
                }
            }

            &__scroll {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 60px;
                background: none;
                border: none;
                text-decoration: none;
                z-index: 3;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 30px;
                    width: 1px;
                    background: $white;
                }
            }

            &__scroll-text {
                margin: 0;
                color: $black;
            }

            &__logo {
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }

                img {
                    margin-left: auto;
                    display: block;
                    width: 150px;
                }
            }

            &__text {
                margin: 0;
                font-weight: 700;
                color: $white;
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }
            }

            &__container {
                position: relative;
                
                @include mq(md) {
                    margin-left: auto;
                    padding-top: 20vh;
                    width: 55%;
                }
            }

            &__container-title {
                color: $white;
                font-weight: 700;
                font-size: 80px;
                margin: 0;
                line-height: .9;

                @include mq(md) {
                    font-size: 100px;
                }

                &:nth-child(1) {
                    margin-top: 50px;

                    @include mq(md) {
                        padding-left: 35px;
                        z-index: 0;
                        margin-top: 0;
                    }
                }

                &:nth-child(2) {
                    margin-bottom: 20px;
                    @include mq(md) {
                        padding-left: 20px;
                        z-index: 2;
                        margin-bottom: 0;
                    }
                }
            }

            &__container-text {
                color: $black;
                font-weight: 300;
                font-size: 18px;
                margin: 0;
                margin-bottom: 10px;
                
                @include mq(md) {
                    position: absolute;
                    bottom: 0;
                    left: 50%;

                }
            }
        }

        &__objetivos {
            background: $lightBlack;
            padding: 50px 0 0 0;
            z-index: 3;
            position: relative;
            
            @include mq(md) {
                padding: 0;
            }

            &__container {
                padding: 0 0 50px 0;

                @include mq(md) {
                    padding: 100px 0;
                }
            }

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
            }

            &__text {
                color: $white;
                width: 80%;
                margin: 0;
            }

            &__item {
                color: $white;
            }
        }

        &__campana {
            position: relative;

            &__container { 
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                
                @include mq(md) {
                    display: flex;
                    top: 0;
                    bottom: auto;
                }
            }

            &__col {
                height: 0;
                width: 100%;
                padding-bottom: 100%;
                overflow: hidden;
                position: relative;
                background: $pink;
                
                @include mq(md) {
                    width: 50%;
                    padding-bottom: 50%;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 100%;
                    height: auto;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                padding-top: 10vw;
                padding-bottom: 110vw;

                @include mq(md) {
                    height: 50vw;
                    padding-bottom: 0;
                    padding-top: 0;
                    width: 80%;
                }
            }

            &__title {
                font-size: 30px;
                font-weight: 900;
                margin: 0;
                margin-bottom: 40px;
                color: $white;

                @include mq(md) {
                    font-size: 50px;
                }
            }

            &__text {
                margin: 0;
                font-size: 16px;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }

        &__rrss {
            padding: 50px 0 20px 0;

            @include mq(md) {
                padding: 100px 0 70px 0;
            }

            &__title {
                margin: 0;
                margin-bottom: 50px;
                font-size: 30px;

                @include mq(md) {
                    margin-bottom: 100px;
                    font-size: 40px;
                }
            }

            &__image {
                position: relative;
                height: 0;
                padding-bottom: 100%;
                width: 100%;
                overflow: hidden;
                margin-bottom: 30px;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 100%;
                    height: auto;
                }
                
                video {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    
                }
                
                &--double {
                    padding-bottom: 50%;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }
            }
        }
    }

    &--natura {
        &__hero {
            height: 100vh;
            position: relative;
            background: url('/images/projects/natura/hero.jpg') no-repeat 62% center / cover;

            @include mq(md) {
              background: url('/images/projects/natura/hero.jpg') no-repeat center center / cover;
            }

            &__scroll {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 60px;
                background: none;
                border: none;
                text-decoration: none;
                z-index: 3;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 30px;
                    width: 1px;
                    background: $white;
                }
            }

            &__scroll-text {
                margin: 0;
                color: $white;
            }

            &__text {
                margin: 0;
                font-weight: 700;
                color: $white;
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }
            }

            &__container {
                position: relative;
                
                @include mq(md) {
                    padding-top: 15vh;
                    width: 100%;
                }
            }

            &__container-title {
                color: $white;
                font-weight: 700;
                font-size: 80px;
                margin: 0;
                line-height: .9;
                margin-bottom: 20px;

                @include mq(md) {
                    font-size: 120px;
                }
            }

            &__container-text {
                color: $white;
                font-weight: 300;
                font-size: 18px;
                margin: 0;
                margin-bottom: 10px;
                
            }
        }

        &__objetivos {
            background: $lightBlack;
            padding: 50px 0 0 0;
            z-index: 3;
            position: relative;
            
            @include mq(md) {
                padding: 0;
            }

            &__container {
                padding: 0 0 50px 0;

                @include mq(md) {
                    padding: 100px 0;
                }
            }

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
            }

            &__text {
                color: $white;
                width: 80%;
                margin: 0;
            }

            &__item {
                color: $white;
            }
        }

        &__capacitacion {
            background: $orange;
            padding: 50px 0;

            @include mq(md) {
                padding: 100px 0;
            }

            &__content {
                margin-bottom: 40px;

                @include mq(md) {
                    width: 70%;
                    margin-bottom: 0;
                    height: 100%;
                }
            }

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
                font-size: 40px;
            }

            &__text {
                color: $white;
                margin: 0;
            }

            &__image {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__mosaic {
            padding: 50px 0 20px 0;

            @include mq(md) {
                padding: 100px 0 70px 0;
            }

            &__title {
                margin: 0;
                font-size: 30px;
                margin-bottom: 20px;
                
                @include mq(md) {
                    font-size: 40px;
                }
            }

            &__text {
                margin-bottom: 50px;

                @include mq(md) {
                    margin-bottom: 100px;
                }
            }

            &__image {
                position: relative;
                height: 0;
                padding-bottom: 100%;
                width: 100%;
                overflow: hidden;
                margin-bottom: 30px;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 100%;
                    height: auto;
                }

                &--half {
                    padding-bottom: 50%;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }
                
                &--big {
                    padding-bottom: 73.5%;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                &--small {
                    padding-bottom: 156%;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                &--double {
                    padding-bottom: 150%;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }
            }
        }

        &__brochure {
            padding: 50px 0;
            background: $orange;

            @include mq(md) {
                padding: 100px 0;
            }

            &__image {
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__videos {
            overflow: hidden;

            .slick-slider {
                margin-bottom: -4px !important;
                margin-top: -4px;
            }

            .slick-prev,
            .slick-next {
                display: block !important;
            }

            .slick-dots {
                display: none !important;
            }

            &__slide {
                height: 0;
                padding-bottom: 56.5%;
                width: 100%;
                overflow: hidden;
                position: relative;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }
    }

    &--bravecto {
        &__hero {
            background: $purple;
            position: relative;
            // padding-top: 15vh;
            padding-bottom: 5vh;
            height: 100vh;
            
            @include mq(md) {
                // padding-top: 25vh;
            }

            &__image {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 2;
                pointer-events: none;

                img {
                    position: absolute;
                    top: 70%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 170px;
                    height: auto;
                    
                    @include mq(md) {
                        width: 270px;
                        top: 50%;
                    }
                }
            }

            &__scroll {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 60px;
                background: none;
                border: none;
                text-decoration: none;
                z-index: 3;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 30px;
                    width: 1px;
                    background: $white;
                }
            }

            &__scroll-text {
                margin: 0;
                color: $white;
            }

            &__logo {
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }

                img {
                    margin-left: auto;
                    display: block;
                    width: 150px;
                }
            }

            &__text {
                margin: 0;
                font-weight: 700;
                color: $white;
                padding-top: 100px;

                @include mq(md) {
                    padding-top: 8vw;
                }
            }

            // &__container {
            //     height: 100%;
            //     display: flex;
            //     flex-direction: column;
            //     justify-content: center;
            // }

            &__container {
                position: relative;
                // flex-direction: column;
                
                @include mq(md) {
                    justify-content: center;
                    align-items: flex-end;
                    display: flex;
                    height: 50vh;
                }
            }

            &__subtitle {
                font-weight: 700;
                color: $white;
                margin: 0;
                margin-bottom: 40px;

                @include mq(md) {
                    margin-bottom: 0;
                }
            }

            &__title {
                font-weight: 700;
                color: $white;
                margin: 0;
                font-size: 60px;
                font-weight: 900;
                text-align: right;

                @include mq(md) {
                    text-align: left;
                    transform: translate3d(0, -50%, 0);
                    top: 75%;
                    margin-bottom: 20px;
                    position: absolute;
                    font-size: 120px;
                }

                &--left {
                    right: 54%;
                    // padding-right: 200px;
                }

                &--right {
                    left: 56%;
                    // padding-left: 10vw;
                }
            }

            &__container-text {
                color: $white;
                margin: 0;
                text-align: right;
                font-size: 16px;
                
                @include mq(md) {
                    text-align: left;
                    position: absolute;
                    right: 20%;
                    bottom: 150px;
                    font-size: 20px;
                }

                @include customMq(1500px) {
                    bottom: 200px;
                }
            }

            // &__image {
            //     width: 100%;

            //     img {
            //         margin-left: auto;
            //         display: block;
            //         width: auto;
            //         height: 200px;

            //         @include mq(md) {
            //             margin-left: 0;
            //             width: 100%;
            //             height: auto;
            //         }
            //     }
            // }
        }

        &__propuesta {
            // background: $lightBlack;
            padding: 50px 0 0 0;
            position: relative;
            overflow: hidden;

            @include mq(md) {
                padding: 100px 0 0 0;
                height: 80vh;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: auto;
                height: 100%;

                @include mq(md) {
                    width: 100%;
                    height: auto;
                }
            }

            // &__subtitle {
            //     margin: 0;
            //     font-weight: 900;
            //     color: $black;
            //     margin-bottom: 40px;
            // }

            &__title {
                margin: 0;
                font-weight: 900;
                margin-bottom: 20px;
                font-size: 30px;
                color: $black;

                @include mq(md) {
                    font-size: 50px;
                }
            }

            &__text {
                color: $black;
                font-size: 16px;
                margin-bottom: 40px;
                
                @include mq(md) {
                    font-size: 20px;
                    margin-bottom: 0;
                }
            }

            &__image {
                width: 100%;
                position: relative;
                height: 100%;
                
                img {
                    width: 100%;
                    height: auto;
                    @include mq(md) {
                        position: absolute;
                        left: -25%;
                        top: -10%;
                        width: 150%;
                        height: auto;

                    }
                }
            }
        }

        &__objetivos {
            background: $lightBlack;

            &__title {
                color: $white;
                margin: 0;
                margin-bottom: 20px;
            }

            &__text {
                color: $white;
                width: 80%;
                margin: 0;
            }

            &__item {
                color: $white;
            }
        }

        &__storytelling {
            position: relative;
            background: $purple;

            &__container { 
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                
                @include mq(md) {
                    display: flex;
                    top: 0;
                    bottom: auto;
                }
            }

            &__col {
                width: 100%;
                overflow: hidden;
                position: relative;
                
                @include mq(md) {
                    width: 50%;
                    padding-bottom: 50%;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 100%;
                    height: auto;
                }
            }

            &__subcol {
                height: 0;
                padding-bottom: 50%;
                width: 100%;
                overflow: hidden;
                position: relative;
                display: block;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                    pointer-events: none;
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                padding-top: 10vw;
                padding-bottom: 110vw;

                @include mq(md) {
                    height: 50vw;
                    padding-bottom: 0;
                    padding-top: 0;
                    width: 80%;
                }
            }

            // &__subtitle {
            //     margin: 0;
            //     margin-bottom: 20px;
            //     color: $white;
            // }

            &__title {
                font-size: 30px;
                font-weight: 900;
                margin: 0;
                margin-bottom: 40px;
                color: $white;

                @include mq(md) {
                    font-size: 45px;
                }
            }

            &__text {
                margin: 0;
                font-size: 16px;
                color: $white;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }

        &__video {
            &__container {
                height: 0;
                padding-bottom: 56.5%;
                width: 100%;
                overflow: hidden;
                position: relative;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: none;
                }
            }
        }

        &__experiencia {
            position: relative;
            background: $lightBlack;
            
            @include mq(md) {
                height: 50vw;
            }

            &__image {
                width: 100%;
                height: auto;
                position: relative;
                height: 50vh;
                margin-bottom: 50px;

                @include mq(md) {
                    height: 100%;
                }

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 100%;
                    height: auto;

                    @include mq(md) {
                    }
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                padding: 50px 0;
                // padding-bottom: 50vh;

                @include mq(md) {
                    height: 50vw;
                    padding: 0;
                    width: 80%;
                }
            }

            &__subtitle {
                margin: 0;
                margin-bottom: 20px;
                color: $white;
            }

            &__title {
                font-size: 30px;
                font-weight: 900;
                margin: 0;
                margin-bottom: 40px;
                color: $white;

                @include mq(md) {
                    font-size: 50px;
                }
            }

            &__text {
                margin: 0;
                font-size: 16px;
                color: $white;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }

        &__medio-digital {
            position: relative;
            overflow: hidden;
            // background: $lightBlack;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: auto;
                height: 100%;

                @include mq(md) {
                    width: 100%;
                    height: auto;
                }
            }

            // &__container { 
            //     position: absolute;
            //     bottom: 0;
            //     left: 0;
            //     width: 100%;
                
            //     @include mq(md) {
            //         display: flex;
            //         top: 0;
            //         bottom: auto;
            //     }
            // }

            // &__col {
            //     width: 100%;
            //     height: 0;
            //     padding-bottom: 100%;
            //     overflow: hidden;
            //     position: relative;

            //     @include mq(md) {
            //         padding-bottom: 50%;
            //     }
                
            //     iframe {
            //         position: absolute;
            //         top: 0;
            //         left: 0;
            //         width: 100%;
            //         height: 100%;
            //         border: none;
            //     }
            // }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                padding: 50px 0;
                // padding-top: 10vw;
                // padding-bottom: 110vw;
                
                @include mq(md) {
                    height: 80vh;
                    padding: 0;
                    width: 85%;
                }
            }

            &__title {
                font-size: 30px;
                font-weight: 900;
                margin: 0;
                color: $black;
                margin-bottom: 20px;

                @include mq(md) {
                    font-size: 60px;
                }
            }

            &__text {
                font-size: 20px;
            }
        }

        // &__facts {
        //     background: $purple;
        //     padding: 50px 0 20px 0;
            
        //     @include mq(md) {
        //         padding: 100px 0 70px 0;
        //     }

        //     &__title {
        //         color: $black;
        //         margin: 0;
        //         margin-bottom: 20px;
        //     }

        //     &__container {
        //         margin-bottom: 30px;

        //         @include mq(md) {
        //             padding-right: 20%;
        //             margin-bottom: 60px;
        //         }
        //     }

        //     &__container-title {
        //         margin: 0;
        //         color: $white;
        //         font-weight: 900;
        //         margin-bottom: 20px;
        //         font-size: 40px;
                
        //         @include mq(md) {
        //             font-size: 60px;
        //         }
        //     }

        //     &__container-text {
        //         margin: 0;
        //         color: $white;
        //     }

        //     &__container-number {
        //         margin: 0;
        //         color: $white;
        //         font-size: 40px;
        //         font-weight: 900;
        //         line-height: .7;

        //         @include mq(md) {
        //             font-size: 80px;
        //         }
        //     }
        // }
    }
}