.blog {
    &__hero {
        background: url('/images/blog/hero.jpg') no-repeat center center / cover;
        position: relative;
        padding: 40vh 0 10vh 0;
        z-index: 4000;
        display: flex;
        align-items: center; 

        @include mq(md) {
            height: 80vh;
            padding: 0;
            padding-top: 9.6vw;
        }

        &__title {
            color: $white;
            font-size: 80px;
            margin: 0;
            font-weight: 900;

            @include mq(md) {
                font-size: 8vw;
            }
        }
    }

    &__articles {
        background: $lightGray;
        padding: 50px 0 20px 0;

        @include mq(md) {
            padding: 100px 0 70px 0;
        }

        &__container {
            display: block;
            text-decoration: none;
            margin-bottom: 30px;
        }
    
        &__image {
            overflow: hidden;
            position: relative;
            height: 0;
            padding-bottom: 56.5%;
    
            @include mq(md) {
                width: 100%;
                height: 50%;
            }
    
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 110%;
                height: auto;                
            }
        }
        
        &__content {
            padding: 40px;
            background: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    
        &__content-subtitle {
            color: $red;
            text-transform: uppercase;
            font-size: 14px;
            margin: 0;
            margin-bottom: 10px;
        }
    
        &__content-title {
            margin: 0;
            font-size: 24px;
            margin-bottom: 20px;
        }
    
        &__content-text {
            margin: 0;
            font-size: 14px;

            span {
                font-weight: 700;
            }
        }
    }
}