.footer {
    @include trans;
    background: $black;
    padding: 40px 0;
    z-index: 3000;
    position: relative;
    
    @include mq(md) {
        padding: 80px 0;
    }

    &__container {
        display: flex;

        &--left {
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;

            @include mq(md) {
                justify-content: flex-start;
                margin-bottom: 0;
            }
        }

        &--right {
            flex-direction: column;
            align-items: center;

            @include mq(md) {
                align-items: flex-end;
            }
        }
    }

    &__logo {
        height: 40px;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid $white;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__text {
        color: $white;
        margin: 0;
        font-weight: 700;
        width: 55%;
        font-size: 14px;
        
        @include mq(md) {
            font-size: 16px;
            width: 40%;
        }
    }

    &__list {
        display: flex;
        margin-bottom: 10px;

        li {
            margin: 10px;

            @include mq(md) {
                margin: 0;
                margin-left: 20px;
            }

            a {
                color: $midGray;
                text-decoration: none;
            }
        }
    }

    &__copyright {
        margin: 0;
        font-size: 14px;
        color: $midGray;
    }
}
