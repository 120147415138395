.home {
    background: #212121;
    color: $white;
    $h: &;

    &__hero {
        background: $white;
        position: relative;
        height: 80vh;
        padding-top: 15.3vh;
        z-index: 4000;
        display: flex;
        align-items: center;

        @include mq(md) {
            height: 100vh;
        }

        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            
            @include mq(md) {
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
                // height: calc(100vh - 9.6vw);
            }

            &:after {
                content: '';
                position: absolute;
                width: 100vw;
                min-height: 140%;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0);
                background: $white;
            }
        }

        &__logo {
            position: relative;
            z-index: 2;
            
            @include mq(md) {
                width: 40%;
            }

            img {
                height: auto;
                width: 50%;

                @include mq(md) {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__wrapper {
            display: flex;
            align-items: center;
            background: $white;
            position: relative;
            z-index: 2;

        }

        &__slogan {
            text-transform: uppercase;
            color: $black;
            margin-right: 10px;
            padding-right: 10px;
            border-right: 1px solid $black;


            span {
                display: block;

                &.small {
                    font-size: 14px;

                    @include mq(md) {
                        font-size: 21px;
                    }
                }

                &.medium {
                    font-size: 16px;

                    @include mq(md) {
                        font-size: 25px;
                    }
                }
                
                &.big {
                    font-size: 26px;

                    @include mq(md) {
                        font-size: 40px;
                    }
                }
            }
        }

        &__text {
            color: $black;
            font-size: 14px;

            @include mq(md) {
                font-size: 24px;
            }

            span {
                font-weight: 900;
            }
        }

        &__slogan-scroll {
            display: block;
            position: absolute;
            left:50%;
            bottom: 0;
            transform: translateX(-55%);
            padding-bottom: 80px;
            text-decoration: none;
            font-size: 18px;

            &:after {
                content: '';
                position: absolute;
                left:0;
                right: 0;
                bottom: 0;
                height: 50px;
                width: 1px;
                margin: auto;
                background: $corpBlack;
            }
        }
    }


    // &__about {
    //     padding: 50px 0;

    //     @include mq(md) {
    //         padding: 100px 0;
    //     }

    //     &__title {
    //         font-size: 50px;
    //         font-weight: 900;
    //         color: $corpBlack;
    //         margin: 0;
    //         margin-bottom: 20px;
    //         padding-bottom: 20px;
    //         border-bottom: 2px solid $midGray;
    //         display: inline-block;
    //         padding-right: 40px;
    //     }

    //     &__text {
    //         margin: 0;
    //         margin-bottom: 40px;

    //         @include mq(md) {
    //             margin-bottom: 0;    
    //         }
    //     }

    //     &__main-title {
    //         margin: 0;
    //         margin-bottom: 40px;
    //         font-size: 40px;

    //         @include mq(md) {
    //             font-size: 60px;
             
    //         }
    //     }

    //     &__main-text {
    //         margin: 0;
    //         font-size: 20px;
            
    //         @include mq(md) {
    //             font-size: 24px;
             
    //         }
    //     }
    // }

    &__work {
        background: $black;
        padding: 50px 0;

        @include mq(md) {
            padding: 100px 0;
        }

        &__group {
            display: flex;
            align-items: flex-end;
            margin-bottom: 40px;
        }
        
        &__title {
            font-weight: 900;
            color: $white;
            margin: 0;
            margin-bottom: 40px;
            font-size: 30px;
            
            a {
                text-decoration: none;
                color: $white;
            }
            
            @include mq(md) {
                font-size: 50px;
            }

            span {
                font-weight: 100;
                font-size: 16px;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }        

        &__container {
            width: 100%;
            height: 0;
            padding-bottom: 80%;
            overflow: hidden;
            position: relative;
            margin-bottom: 30px;
            background: $black;
            display: block;

            img {
                @include trans;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                height: 100%;
                width: auto;
                min-height: 100%;
            }

            &--big {
                padding-bottom: 80%;

                @include mq(md) {
                    padding-bottom: calc(40% - 15px);
                }

                img {
                    height: 100%;
                    width: auto;

                    @include mq(md) {
                        height: auto;
                        width: 100%;
                    }
                }
            }

            &:hover {
                img {
                    opacity: .7;
                }

                .home__work__wrapper {
                    transform: translateY(0);
                }

                .home__work__wrapper-text {
                    opacity: 1;
                }
            }
        }

        &__wrapper {
            @include trans;
            position: absolute;
            top: 20px;
            left: 20px;

            @include mq(md) {
                top: 40px;
                left: 40px;
                transform: translateY(100%);
            }
        }

        &__wrapper-title {
            color: $white;
            margin: 0;
            margin-bottom: 20px;
            font-size: 40px;
        }

        &__wrapper-text  {
            @include trans;
            color: $white;
            margin: 0;
            text-decoration: underline;
            text-transform: uppercase;
            
            @include mq(md) {
                opacity: 0;
            }
        }
    }

    &__blog {
        background: $lightestGray;
        padding: 50px 0;

        @include mq(md) {
            padding: 100px 0;
        }
        
        &__title {
            font-weight: 900;
            margin: 0;
            margin-bottom: 40px;
            font-size: 30px;

            a {
                text-decoration: none;
            }
            
            @include mq(md) {
                font-size: 50px;
            }

            span {
                font-weight: 100;
                font-size: 16px;
                                
                @include mq(md) {
                    font-size: 20px;
                }
            }
        }        

        // &__text {
        //     margin: 0;
        //     margin-bottom: 40px;
        //     color: $midGray;
        // }

        &__container {
            width: 100%;
            overflow: hidden;
            display: block;
            text-decoration: none;
            
            @include mq(md) {
                height: 30vw;
            }

            &--image-right {
                margin-bottom: 40px;
                display: flex;
                flex-direction: column;

                @include mq(md) {
                    flex-direction: row;
                    margin-bottom: 0;
                }

                #{$h}__blog__content {
                    order: 2;

                    @include mq(md) {
                        width: 40%;
                        order: 1;
                    }
                }
                
                #{$h}__blog__image {
                    height: 30vh;
                    width: 100%;
                    order: 1;

                    @include mq(md) {
                        order: 2;
                        width: 60%;
                        height: 100%;
                    }
                }

                #{$h}__blog__content-subtitle {
                    margin-bottom: 20px;
                }

                #{$h}__blog__content-text {
                    span {
                        display: block;
                    }
                }
            }

            &--image-up {
                #{$h}__blog__content {
                    width: 100%;
                    
                    @include mq(md) {
                        height: 50%;
                    }
                }

                #{$h}__blog__image {
                    height: 30vh;

                    @include mq(md) {
                        width: 100%;
                        height: 50%;
                    }
                }

                #{$h}__blog__content-subtitle {
                    margin-bottom: 10px;
                }
            }
        }

        &__content {
            padding: 30px;
            background: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__image {
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                height: auto;
                width: 100%;

                @include mq(md){
                    height: 110%;
                    width: auto;
                }
            }
        }

        &__content-subtitle {
            color: $red;
            text-transform: uppercase;
            font-size: 14px;
            margin: 0;
        }

        &__content-title {
            margin: 0;
            font-size: 20px;
            margin-bottom: 20px;

            @include mq(md) {
                margin-bottom: 0;
            }
        }

        &__content-text {
            margin: 0;
            font-size: 14px;
            color: $black;

            span {
                font-weight: 700;
            }
        }
    }
}
