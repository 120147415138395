.work {

    $w: &;

    position: relative;
    height: 100vh;
    overflow: hidden;
    z-index: 4000;
    width: 100%;

    
    &__proyect {
        
        position: absolute;
        bottom: -100vh;
        background: $black;
        display: block;
        text-decoration: none;
        
        img {
            // opacity: 0;
            // height: 0;
            position: absolute;
            top: 50%;
            left: 20%;
            transform: translate3d(-50%, -50%, 0);
            height: 100%;
            width: auto;

            @include mq(sm) {
                width: 100%;
                height: auto;
                left: 50%;
            }
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // height: 100vh;
            // width: 100%;
            background: $white;
            transform: translateY(-100%);
            opacity: 0;
            z-index: 3000;
        }

        .col-24 {
            @include trans;
            transition-delay: .3s;
            opacity: 0;
            transform: translateY(100%);
        }

        &.js-active {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding-top: 20vh;
            height: 100vh;
            width: 100%;
            z-index: 10000;

            img {
                // height: auto;
                opacity: .7;
            }

            .col-24 {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &.js-overlay-down {
            &:after {
                opacity: 1;
                animation: overlay .6s linear forwards;
            }            
        }

        &.js-overlay-up {
            &:after {
                opacity: 1;
                animation: overlay .6s linear forwards reverse;
            }            
        }
    }

    &__uppertitle {
        font-weight: 700;
        font-size: 18px;
        color: $white;
        padding-right: 100px;
        margin: 0;
        border-top: 1px solid $white;
        display: inline-block;
        padding-top: 20px;
        margin-bottom: 80px;
    }

    &__title {
        font-size: 12vw;
        color: $white;
        font-weight: 900;
        margin: 0;
        display: block;
        text-decoration: none;
        margin-bottom: 20px;
        
        @include mq(md) {
            font-size: 120px;

        }
    }

    &__company {
        color: $white;
        text-transform: uppercase;
        font-size: 7vw;
        margin: 0;
        margin-bottom: 80px;

        @include mq(md) {
            font-size: 30px;
        }
    }

    &__proyect-name {
        color: $white;
        margin: 0;
        font-weight: 900;
        font-size: 24px;
    }

    &__indicator {
        position: absolute;
        bottom: 50px;
        transform: rotate(-90deg);
        color: $white;
        padding-left: 80px;
        left: 8%;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: 1px;
            width: 75px;
            background: $white;
        }
    }

    & + footer {
        display: none;
    }

    &.-footer-active {
        z-index: 2000;

        & + footer {
            position: absolute;
            top: 100vh;
            width: 100%;
            display: block;
        }
    }
}

@keyframes overlay {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(100%);
    }
}