.about {
    &__hero {
        background: url('/images/about/hero.jpg') no-repeat center center / cover;
        position: relative;
        z-index: 4000;
        display: flex;
        align-items: center; 
        height: 80vh;
        padding-top: 14vh;

        @include mq(md) {
            padding-top: 9.6vw;
        }

        .container {
            @include customMq(1700px) {
                max-width: 1600px;
            }
        }

        &__title {
            color: $white;
            margin: 0;
            font-weight: 900;
            font-size: 80px;

            @include mq(md) {
                font-size: 8vw;
            }

            br {
                @include customMq(1700px) {
                    display: none;
                }
            }
        }
    }

    &__description {
        padding: 50px 0;
        background: $black;

        @include mq(md) {
            padding: 100px 0;
        }

        
        &__title {
            font-weight: 900;
            color: $white;
            margin: 0;
            margin-bottom: 80px;
            font-size: 30px;
            
            @include mq(md) {
                font-size: 50px;
            }

            span {
                font-weight: 100;
                font-size: 16px;

                @include mq(md) {
                    font-size: 20px;
                }
            }
        }

        &__group {
            margin-bottom: 40px;
        }

        &__chart-title {
            color: $white;
            margin: 0;
            font-size: 30px;
            font-weight: 300;
            @include mq(md) {
                font-size: 50px;
                
            }
        }

        &__chart-text {
            color: $white;
            font-size: 14px;

            @include mq(md) {
                font-size: 16px;
            }

            &--strong {
                text-transform: uppercase;
                color: $red;
                font-weight: 700;
            }
        }


        &__main-title {
            color: $red;
            margin: 0;
            letter-spacing: -2px;
            margin-bottom: 40px;
            font-weight: 300;
            font-size: 36px;
            
            @include mq(md) {
                font-size: 60px;
            }
        }

        &__image {
            width: 100%;
            overflow: hidden;
            margin-bottom: 40px;

            img {
                width: 100%;
                height: auto;
            }
        }
        &__list-title {
            font-weight: 900;
            color: $white;
            font-size: 20px;
            margin-bottom: 40px;
            
            @include mq(md) {
                height: 60px;
                font-size: 24px;
            }
        }
    
        &__list {
            margin-bottom: 40px;
    
            @include mq(md) {
                margin-bottom: 0;
            }
        }
    
        &__list-item {
            width: 100%;
            height: 40px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            color: $white;
            
            &:not(:last-child) {
                border-bottom: 1px solid $darkestGray;
            }
        }
    }

    &__interstitial {
        background: $lightGray;
        padding: 50px 0;

        @include mq(md) {
            padding: 100px 0;
        }
        
        &__title {
            margin: 0;
            margin-bottom: 20px;
            font-size: 20px;
            
            @include mq(md) {
                font-size: 24px;
            }
        }

        &__text {
            margin: 0;
            margin-bottom: 40px;
            line-height: 1.5;

            @include mq(md) {
                margin-bottom: 0;
            }
        }
    }

    // &__location {
    //     padding: 50px 0 10px 0;

    //     @include mq(md) {
    //         padding: 100px 0;
    //     }

    //     &__title {
    //         display: inline-block;
    //         padding-right: 100px;
    //         border-top: 1px solid $corpBlack;
    //         margin: 0;
    //         padding-top: 20px;
    //         margin-bottom: 40px;
    //     }

    //     &__main-title {
    //         margin: 0;
    //         font-size: 20px;
    //         margin-bottom: 20px;
            
    //         @include mq(md) {
    //             font-size: 28px;
    //         }
    //     }

    //     &__text {
    //         font-size: 16px;
    //         margin: 0;
    //         margin-bottom: 40px;

    //         @include mq(md) {
    //             margin-bottom: 0;
    //             font-size: 20px;
    //         }            
    //     }
    // }
}