.header {

    $h: &;

    @include trans;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5000;
    height: 0;
    
    &:before {
        @include trans;
        
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 0;
        margin: auto;
        width: 100vw;
        height: 100vh;
        border: 0vw solid $lightGray;
        display: block;
   }
    
    &.js-hovered {
        &:before {
            @include mq(md) {
                border-top: 7vw solid $lightGray;
                border-left: 3.5vw solid $lightGray;
                border-right: 3.5vw solid $lightGray;
                border-bottom: 3.5vw solid $lightGray;
            }    
        }
    }

    &__button {
        @include trans;
        
        position: absolute;
        top: 4.3vw;
        left: 0;
        right: 0;
        margin: auto;
        width: 60px;
        height: 60px;
        z-index: 5000;
        background: $lightGray;
        overflow: hidden;
        cursor: pointer;
        animation: clickMe 5s linear infinite;
        animation-delay: 5s;
        
        @include mq(md) {
            width: 75px;
            height: 75px;
        }

        &:hover {
            animation: none;
            .js-main-logo {
                filter: brightness(1000%);
                z-index: 6000;
            }
        }

        img {
            @include trans;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);

            &.js-main-logo {
                @include trans;
                height: 50%;
                width: auto;
                pointer-events: none;                
            }

            &.js-internal-logo {
                @include trans;
    
                height: 0;
                overflow: hidden;
                opacity: 0;
                width: 100%;
            }
        }
    }
    
    &__container {
        @include trans;
        transform: translateY(-100%);
        background: $lightGray;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding-top: 14vh;
        padding-bottom: 100px;

        @include mq(md) {
            padding: 180px 0 80px;

        }
    }

    &__nav {
        @include trans;

        display: flex;
        justify-content: center;
        width: 100%;
        max-width: 1260px;

    }

    &__list {
        width: 80%;

        @include mq(md) {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

        }

        li {
            position: relative;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 40px;
            }
            
            @include mq(md) {
                margin-bottom: 40px;
                padding-bottom: 40px;
                width: 15%;
            }

            &:after {
                @include mq(md) {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 40px;
                    height: 2px;
                    background: $darkGray;
                }
            }

            &:hover {
                a {
                    h2 {
                        color: $red
                    }
                }
            }
        }

        a {
            @include trans;

            display: flex;
            justify-content: space-between;
            align-items: center;
            color: $black;
            text-decoration: none;
            
            @include mq(md) {
                display: block;
                text-align: center;

            }

            h2 {
                @include trans;
                font-weight: 700;
                margin: 0;
                margin-bottom: 5px;
                letter-spacing: -1px;
                
                @include mq(md) {
                    font-size: 40px;
                }
            }

            p {
                margin: 0;
            }
        }
    }

    &__social {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 0;
        // opacity: 0;

        li {
            margin: 0 15px;

            a {
                @include trans;
                text-decoration: none;
                color: $black;

                i {
                    font-weight: 700;
                    font-size: 24px;
                }
            }

            &:hover {
                a{
                    color: $red;
                }
            }
        }
    }

    // &__hamburguer {
    //     cursor: pointer;
    //     width: 25px;
    //     // height: 25px;
    //     // margin: auto;
    //     // position: absolute;
    //     // right: 32px;
    //     // top: 30px;

    //     span {
    //         background-color: $mainColor;
    //         border-radius: 2px;
    //         content: '';
    //         display: block;
    //         width: 100%;
    //         height: 2px;

    //         &:nth-child(1) {
    //             animation:outT 0.6s $easing backwards;
    //             animation-direction:reverse;
    //         }
    //         &:nth-child(2) {
    //             margin: 7px 0;
    //             animation:outM 0.6s $easing backwards;
    //             animation-direction:reverse;
    //         }
    //         &:nth-child(3) {
    //             animation:outBtm 0.6s $easing backwards;
    //             animation-direction:reverse;
    //         }
    //     }
    // }

    &.-open {
        height: auto;
        // #{$h}__hamburguer {
        //     span:nth-child(1) {
        //         animation:inT 0.6s $easing forwards;
        //     }
        //     span:nth-child(2) {
        //         animation:inM 0.6s $easing forwards;
        //     }
        //     span:nth-child(3) {
        //         animation:inBtm 0.6s $easing forwards;
        //     }
        // }
        &:before {
            border-top: 7vw solid $lightGray;
            border-left: 3.5vw solid $lightGray;
            border-right: 3.5vw solid $lightGray;
            border-bottom: 3.5vw solid $lightGray;
        }
                
        & + main {
            transform: translateY(405px);
        }
    
        // #{$h}__logo {
        //     margin-bottom: 40px;
        //     height: auto;
        //     opacity: 1;

        // }

        #{$h}__container {
            transform: translateY(0);
        }

        // #{$h}__list,
        // #{$h}__social {
        //     height: auto;
        //     opacity: 1;
        // }
        .overlay  {
            background: none;
        }
        #{$h}__button {
            background: transparent;
            height: 5vw;
            animation: none;

            .js-internal-logo {
                height: auto;
                overflow: visible;
                opacity: 1;
            }

            .js-main-logo {
                height: 0;
                overflow: hidden;
                opacity: 0;
                pointer-events: none;
            }
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}

$duration: 300ms;
$timing-fn: ease;

.in-top .overlay {
    transform-origin: 50% 0%;
    animation: in-top $duration $timing-fn 0ms 1 forwards;
}
.in-right .overlay {
    transform-origin: 100% 0%;
    animation: in-right $duration $timing-fn 0ms 1 forwards;
}
.in-bottom .overlay {
    transform-origin: 50% 100%;
    animation: in-bottom $duration $timing-fn 0ms 1 forwards;
}
.in-left .overlay {
    transform-origin: 0% 0%;
    animation: in-left $duration $timing-fn 0ms 1 forwards;
}

.out-top .overlay {
    transform-origin: 50% 0%;
    animation: out-top $duration $timing-fn 0ms 1 forwards;
}
.out-right .overlay {
    transform-origin: 100% 50%;
    animation: out-right $duration $timing-fn 0ms 1 forwards;
}
.out-bottom .overlay {
    transform-origin: 50% 100%;
    animation: out-bottom $duration $timing-fn 0ms 1 forwards;
}
.out-left .overlay {
    transform-origin: 0% 0%;
    animation: out-left $duration $timing-fn 0ms 1 forwards;
}

@keyframes in-top {
    from {transform: rotate3d(-1,0,0, 90deg)}
    to   {transform: rotate3d(0,0,0, 0deg)}}
@keyframes in-right {
    from {transform: rotate3d(0,-1,0, 90deg)}
    to   {transform: rotate3d(0,0,0, 0deg)}}
@keyframes in-bottom {
    from {transform: rotate3d(1,0,0, 90deg)}
    to   {transform: rotate3d(0,0,0, 0deg)}}
@keyframes in-left {
    from {transform: rotate3d(0,1,0, 90deg)}
    to   {transform: rotate3d(0,0,0, 0deg)}}
@keyframes out-top {
    from {transform: rotate3d(0,0,0, 0deg)}
    to   {transform: rotate3d(-1,0,0, 104deg)}}
@keyframes out-right {
    from {transform: rotate3d(0,0,0, 0deg)}
    to   {transform: rotate3d(0,-1,0, 104deg)}}
@keyframes out-bottom {
    from {transform: rotate3d(0,0,0, 0deg)}
    to   {transform: rotate3d(1,0,0, 104deg)}}
@keyframes out-left {
    from {transform: rotate3d(0,0,0, 0deg)}
    to   {transform: rotate3d(0,1,0, 104deg)}}

    
.overlay {
    // hide-initial-state
    transform: rotate3d(1,0,0, 90deg);
    
    position: absolute;
    width: 110%;
    height: 110%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-color: $red;
  }

  @keyframes clickMe {
      0% {background: $lightestGray}
      10% {background: $red}
      20% {background: $lightestGray}
      100% {background: $lightestGray}
  }
  